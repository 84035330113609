<template>
  <div
    style="
      height: 8.2051rem;
      overflow: hidden;
      background: linear-gradient(to top, #202945, #161e34);
    "
  >
    <div class="content" :style="isConfirm">
      <div class="popups">
        <h3 class="title">{{ $t('nft.issuance.quantity') }}</h3>
        <div class="nftName">
          {{ $t('nft.issuance.nftName') }}:{{ nftName }}
        </div>
        <div class="ipt">
          <span class="remove" @click="kntNum--"></span>
          <input type="number" :min-number="0" v-model="kntNum" />
          <span class="add" @click="kntNum++"></span>
        </div>
        <div class="blance">
          {{ $t('nft.issuance.balance') }}: {{ balance }} KNT
        </div>
        <div class="total">
          {{ $t('nft.issuance.price') }}: {{ nftPrice }} KNT
        </div>
        <div class="btn">
          <button @click="close">{{ $t('nft.issuance.close') }}</button>
          <button @click="changeConfirm">
            {{ $t('nft.issuance.confirm') }}
          </button>
        </div>
        <div class="close" @click="close"></div>
      </div>
      <div class="confirmPopups">
        <h3 class="title">{{ $t('nft.issuance.confirmationPayment') }}</h3>
        <div class="nftName">
          {{ $t('nft.issuance.nftName') }}:{{ nftName }}
        </div>
        <div class="ipt">{{ nftPrice }} KNT</div>
        <div class="blance">
          {{ $t('nft.issuance.balance') }}:{{ balance }} KNT
        </div>
        <div class="btn">
          <button @click="close">{{ $t('nft.issuance.close') }}</button>
          <button @click="confirmNft">{{ $t('nft.issuance.confirm') }}</button>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import address from '@/web3/nft/address.json'
  import { Toast } from 'vant'
  import { computed, onMounted, reactive, ref, toRefs } from 'vue'
  import {
    buyNft,
    currencyEntities,
    balanceOf,
    precision,
    approve,
  } from '@/api/nft.js'
  import { formatAmount } from '@/utils/format.js'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  export default {
    name: 'Add',
    props: ['nftName', 'catId', 'nftNum', 'price', 'nftresidue'],
    emits: ['closeDialog', 'resetPage'],
    setup(props, context) {
      const { t } = useI18n()
      const router = useRouter()
      //#region 确认框切换
      let kntNum = ref(null)
      let isConfirm = reactive({
        transform: 'translateY(0)',
        transition: 'all 0.2s ease',
      })
      let dataObject = reactive({
        nftName: computed(() => props.nftName),
        catId: computed(() => props.catId),
        // nftNum: computed(() => props.nftNum),
        nftPrice: computed(() => formatAmount(props.price * kntNum.value, 18)),
        balance: 0,
        nftResidue: computed(() => props.nftresidue),
      })
      getData()
      async function getData() {
        let decimals = await precision()
        let result = await balanceOf()
        dataObject.balance = formatAmount(Number(result), Number(decimals))
      }
      let changeConfirm = () => {
        if (kntNum.value <= 0) return Toast.fail(t('toast.positiveInteger'))
        if (kntNum.value.toString().split('.').length > 1)
          return Toast.fail(t('toast.positiveInteger'))
        if (dataObject.balance < Number(dataObject.nftPrice))
          return Toast.fail(t('toast.balance'))
        if (dataObject.nftResidue < kntNum.value)
          return Toast.fail(t('toast.exceeds'))
        isConfirm.transform = 'translateY(-8.2051rem)'
      }
      let confirmNft = async () => {
        Toast.loading({
          message: t('toast.authorization'),
          forbidClick: true,
          duration: 0,
          loadingType: 'spinner',
        })
        try {
          await approve()
          Toast.loading({
            message: t('toast.loading'),
            forbidClick: true,
            duration: 0,
            loadingType: 'spinner',
          })
          try {
            await buyNft(props.catId, address.token, address.nft, kntNum.value)
            Toast.success(t('toast.win'))
            router.push({ name: 'Nft' })
          } catch (error) {
            console.log(error)
            Toast.fail(t('toast.fail'))
          }
        } catch (error) {
          Toast.fail(t('toast.fail'))
        }
      }
      let close = () => {
        context.emit('closeDialog')
      }
      let reset = () => {
        isConfirm.transform = 'translateY(0rem)'
        kntNum.value = null
      }
      //#endregion
      return {
        isConfirm,
        changeConfirm,
        kntNum,
        confirmNft,
        close,
        ...toRefs(dataObject),
        reset,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .confirmPopups {
    height: 8.2051rem;
    background: linear-gradient(to top, #202945, #161e34);
    color: #fff;
    padding: 0.5128rem;
    .title {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 0.4615rem;
      font-weight: 700;
    }
    .nftName {
      margin-top: 0.8205rem;
      opacity: 0.5;
      font-size: 0.4103rem;
    }
    .ipt {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.4103rem;
      font-size: 0.6154rem;
    }
    .blance {
      opacity: 0.5;
      margin-top: 0.8718rem;
    }
    .close {
      width: 0.4103rem;
      height: 0.4103rem;
      position: absolute;
      right: 0.3077rem;
      top: 0.3077rem;
      background: url('./images/close@2x.png') center no-repeat;
      background-size: 0.4103rem;
    }
    .btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 0.8974rem;
      width: 100%;
      button {
        height: 1.0256rem;
        width: 2.8718rem;
        border-radius: 0.5128rem;
        background-color: #313748;
        color: #fff;
        font-size: 0.4103rem;
      }
      :nth-child(2) {
        background: linear-gradient(to right, #ffd25e, #d28500);
      }
    }
  }
  .content {
    background: linear-gradient(to top, #202945, #161e34);
    .popups {
      height: 8.2051rem;
      background: linear-gradient(to top, #202945, #161e34);
      color: #fff;
      padding: 0.5128rem;
      .title {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 0.4615rem;
        font-weight: 700;
      }
      .nftName {
        margin-top: 0.8205rem;
        opacity: 0.5;
        font-size: 0.4103rem;
      }
      .ipt {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.4103rem;
        input {
          margin: 0 0.5128rem;
          width: 3.0769rem;
          height: 1.0256rem;
          background: #333a50;
          border: 1px solid #899ac9;
          border-radius: 0.2051rem;
          outline: none;
          text-align: center;
        }
        span {
          width: 0.8205rem;
          height: 0.8205rem;
        }
        .add {
          background: url('./images/add@2x.png') center no-repeat;
          background-size: 100%;
        }
        .remove {
          background: url('./images/remove@2x.png') center no-repeat;
          background-size: 100%;
        }
      }
      .blance {
        opacity: 0.5;
        margin-top: 0.4103rem;
      }
      .total {
        opacity: 0.5;
      }
      .close {
        width: 0.4103rem;
        height: 0.4103rem;
        position: absolute;
        right: 0.3077rem;
        top: 0.3077rem;
        background: url('./images/close@2x.png') center no-repeat;
        background-size: 0.4103rem;
      }
      .btn {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 0.8205rem;
        width: 100%;
        button {
          height: 1.0256rem;
          width: 2.8718rem;
          border-radius: 0.5128rem;
          background-color: #313748;
          color: #fff;
          font-size: 0.4103rem;
        }
        :nth-child(2) {
          background: linear-gradient(to right, #ffd25e, #d28500);
        }
      }
    }
  }
</style>
