<template>
  <div style="padding-bottom: 0.7692rem">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      v-model:error="slip"
      :finished-text="t('nft.issuance.noMore')"
      :error-text="t('nft.issuance.requestFailed')"
      :loading-text="t('nft.issuance.loading')"
      @load="onLoad"
    >
      <ul class="market">
        <li
          class="list"
          v-for="item in nftSaleEntities"
          :key="item.tokenId"
          :data-saleid="item.saleId"
          :data-tokenid="item.tokenId"
          :data-seller="item.seller"
          :data-price="item.price"
          :data-nft="item.nftAddress"
          @click="goDetail"
        >
          <div class="marketImg">
            <img
              :src="item.image ? item.image : errorImg"
              @error.once="imgerror"
            />
          </div>
          <div class="title">{{ item.name }}</div>
          <div class="marketPrice">
            <span style="opacity: 0.5">{{ $t('nft.issuance.price') }}</span>
            <span class="price">{{ checkPrice(item.price) }}KNT</span>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
  import ipfs from '@/utils/ipfs.js'
  import { List } from 'vant'
  import { computed, reactive, ref, toRefs } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { nftSaleEntities, getCatMetadata, precision } from '@/api/nft.js'
  import { formatAmount } from '@/utils/format.js'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'NftTradingMarket',
    setup() {
      const { t } = useI18n()
      let router = useRouter()
      const route = useRoute()
      const store = useStore()
      let userData = reactive({
        nftSaleEntities: [],
        accuricy: 18,
      })
      //上滑刷新需要的控制
      let loading = ref(false)
      let finished = computed(() => store.state.isFinished)
      let slip = ref(false)
      let skip = 0
      let page = -1
      store.commit('CHANGEFINISHED', false)
      initData()
      async function initData() {
        userData.accuricy = await precision()
        page++
        skip = page * 4
        try {
          let resultData = (await nftSaleEntities(skip, 4)).data.nftSaleEntities
          let result = resultData.filter((item) => item.status == 0)
          result = await Promise.all(
            result.map(async (item) => {
              let data = await getCatMetadata(item.tokenId)
              let name = data.metadata.name
              let image = ipfs(data.metadata.image)
              return { ...item, name, image }
            })
          )
          userData.nftSaleEntities.push(...result)
          loading.value = false
          if (resultData.length < 4) store.commit('CHANGEFINISHED', true)
        } catch (error) {
          slip.value = true
        }
      }
      let onLoad = () => {
        initData()
      }

      //点击去详情
      function goDetail(e) {
        router.push({
          name: 'ProductDescription',
          query: {
            f: route.query.f,
            id: e.currentTarget.dataset.saleid,
            seller: e.currentTarget.dataset.seller,
            tokenid: e.currentTarget.dataset.tokenid,
            price: e.currentTarget.dataset.price,
            nft: e.currentTarget.dataset.nft,
            identify: 'market',
          },
        })
      }
      let errorImg = require('@/assets/error.jpg')
      let imgerror = (e) => {
        e.target.src = errorImg
      }
      let checkPrice = (price) => {
        let p = Number(formatAmount(price, userData.accuricy))
        if (p.toString().split('.')[1]?.length > 6) {
          return p.toFixed(6)
        }
        return p
      }
      return {
        goDetail,
        ...toRefs(userData),
        onLoad,
        loading,
        finished,
        slip,
        errorImg,
        imgerror,
        checkPrice,
        t,
      }
    },
    components: { [List.name]: List },
  }
</script>

<style lang="scss" scoped>
  .market {
    padding: 0.4103rem 0.3846rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .list {
      width: 49%;
      height: 6.1538rem;
      margin-bottom: 0.4103rem;
      padding: 0.2051rem;
      background: linear-gradient(to bottom, #202945, #161e34);
      border-radius: 0.3077rem;

      .marketImg {
        width: 100%;
        height: 4.1026rem;

        img {
          width: 100%;
          border-radius: 0.2051rem;
          height: 100%;
        }
      }

      .title {
        color: #fff;
        font-size: 0.4103rem;
        margin: 0.2051rem 0;
      }

      .marketPrice {
        display: flex;
        justify-content: space-between;

        span {
          color: #fff;
          font-size: 0.359rem;
        }

        .price {
          color: #e1ab01;
        }
      }
    }
  }
</style>
