<template>
  <div class="content">
    <div class="nftImg">
      <h2><span></span></h2>
      <h4></h4>
    </div>
    <div class="ntfBtn">
      <button :class="isShow ? 'pick' : ''" @click="changeShow" data-ref="issuance">
        {{ $t('nft.main.issuance') }}
      </button>
      <button :class="isShow ? '' : 'pick'" @click="changeShow" data-ref="market">
        {{ $t('nft.main.market') }}
      </button>
    </div>
    <div class="mian">
      <NftDetail v-if="isShow"></NftDetail>
      <NftTradingMarket v-if="!isShow"></NftTradingMarket>
    </div>
  </div>
</template>

<script>
import NftDetail from './NftDetail'
import NftTradingMarket from './NftTradingMarket'
import { ref, reactive, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'Nft',
  setup() {
    const store = useStore()
    //#region tab栏切换
    let isShow = computed(() => store.state.isShow)
    let changeShow = (e) => {
      if (e.target.dataset.ref == 'issuance') {
        store.commit('CHANGESHOW', true)
      } else if (e.target.dataset.ref == 'market') {
        store.commit('CHANGESHOW', false)
      }
    }
    //#endregion
    return {
      //#region tab栏切换的函数和数据
      isShow,
      changeShow,
      //#endregion
    }
  },
  components: { NftTradingMarket, NftDetail },
}
</script>

<style lang="scss" scoped>
.content {
  min-height: 100vh;
  width: 100%;
  padding-bottom: 0.5128rem;
  padding-top: 1.5385rem;
  background: url('./images/nftBG3x.png') center 1.5385rem no-repeat;
  background-size: 100%;

  .nftImg {
    width: 100%;
    height: 6.4103rem;
    color: #fff;
    padding: 0.9744rem 0 0 0.6154rem;

    h2 {
      font-size: 0.6154rem;
      width: 4.4359rem;

      span {
        background: -webkit-linear-gradient(left, #ffd200, #ffb52a);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    h4 {
      font-size: 0.3077rem;
      width: 5.7949rem;
    }
  }

  .ntfBtn {
    display: flex;
    width: 9.2308rem;
    height: 1.0256rem;
    margin: 0 auto;
    border-radius: 0.3077rem;

    button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #92959c;
      background-color: #292f3e;
      font-size: 0.4103rem;
    }

    :nth-child(1) {
      border-radius: 0.2051rem 0 0 0.2051rem;
    }

    :nth-child(2) {
      border-radius: 0 0.2051rem 0.2051rem 0;
    }

    .pick {
      background-color: #e7a92c;
      color: #fff;
    }
  }
}
</style>
