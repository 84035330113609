<template>
  <div>
    <div class="details">
      <van-list
        v-model:loading="loading"
        :finished="isDetail"
        :loading-text="t('nft.issuance.loading')"
        v-model:error="slip"
        :finished-text="t('nft.issuance.noMore')"
        :error-text="t('nft.issuance.requestFailed')"
        @load="onLoad"
      >
        <ul class="detailsList">
          <li
            v-for="item in detailsList"
            :data-price="item.price"
            :data-num="item.number"
            :data-catid="item.catId"
            :data-nftName="item.name"
            :data-pick="item.id"
            :data-currenciesid="item.currencies[0].id"
            :data-nftresidue="item.residue"
            :data-pickid="item.id"
            :data-img="ipfs(item.image)"
            :key="item.id"
            :class="pickClass == item.id ? 'pick' : ''"
            @click="pickCommodity"
          >
            <div class="detailsImg">
              <img :src="item.image ? ipfs(item.image) : errorImg" @error.once="imgerror" />
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="detailsNum">
              <span class="num">{{ t('nft.issuance.margin') }}:{{ item.residue }}/{{ item.number }}</span>
            </div>
            <span class="price">{{ checkPrice(item.price) }}knt</span>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>

<script>
  import DetailsPopup from './DetailsPopup'
  import { Dialog, List } from 'vant'
  import { useRouter } from 'vue-router'
  import { reactive, ref, toRefs, computed } from 'vue'
  import { useStore } from 'vuex'
  import { formatAmount } from '@/utils/format.js'
  import { catagories, nftprojectDtos, currencyEntities, approve, catMetadata, precision } from '@/api/nft.js'
  import { useI18n } from 'vue-i18n'
  import ipfs from '@/utils/ipfs.js'
  export default {
    name: 'NftDetail',
    setup(props) {
      //数据
      const router = useRouter()
      const { t } = useI18n()
      const store = useStore()
      let detailsList = reactive([])
      //#region 弹窗购买模块
      const popupComponent = ref(null)
      let show = ref(false)
      let closeDialog = () => {
        show.value = false
      }
      //#endregion
      //列表数据
      let accuricy = ref(18)
      let loading = ref(false)
      let isDetail = computed(() => store.state.isDetail)
      let slip = ref(false)
      let skip = 0
      let page = -1
      store.commit('CHANGDETAIL', false)
      initData()
      //获取遍历的数据
      async function initData() {
        accuricy.value = await precision()
        let currency = (await currencyEntities()).data.currencyEntities
        page++
        skip = page * 4
        try {
          let resultData = (await catagories(skip, 4)).data.catagories
          let result = resultData.map((item) => {
            let arr = currency.filter((key) => {
              return item.currencies[0].id == key.id
            })

            let price = arr[0]?.amount
            return { ...item, price }
          })
          result = await Promise.all(
            result.map(async (item) => {
              if (item !== undefined) {
                let data = await catMetadata(item.catId)
                let residue = data.remainder
                return { ...item, residue }
              }
            })
          )
          result = result.filter((item) => item !== undefined && Number(item.residue) > 0)
          detailsList.push(...result)
          loading.value = false
          if (resultData.length < 4) store.commit('CHANGDETAIL', true)
        } catch (error) {
          console.log(error)
          slip.value = true
        }
      }

      let pickClass = ref()

      let back = () => {
        router.go(-1)
      }
      //传递的props
      let listData = reactive({
        nftNum: 0,
        nftName: 0,
        catId: 0,
        price: 0,
        nftresidue: 0,
        id: 0,
        image: '',
      })
      // 购买成功后刷新列表
      let resetPage = (num) => {
        let index = detailsList.findIndex((item) => item.id == listData.id)
        if (detailsList[index].residue - num <= 0) {
          return detailsList.splice(index, index + 1)
        }
        detailsList[index].residue = detailsList[index].residue - num
      }
      async function price(id) {
        let result = (await currencyEntities()).data.currencyEntities
        result.some((item) => {
          if (item.id == id) {
            listData.price = item.amount
            return true
          }
        })
      }
      let checkPrice = (price) => {
        let p = Number(formatAmount(price, accuricy.value))
        if (p.toString().split('.')[1]?.length > 6) {
          return p.toFixed(6)
        }
        return p
      }
      // 点击选中此商品,并把值赋值给props对象
      let pickCommodity = (e) => {
        price(e.currentTarget.dataset.currenciesid)
        listData.nftNum = e.currentTarget.dataset.num
        listData.price = e.currentTarget.dataset.price
        listData.nftName = e.currentTarget.dataset.nftname
        listData.catId = e.currentTarget.dataset.catid
        listData.nftresidue = e.currentTarget.dataset.nftresidue
        pickClass.value = e.currentTarget.dataset.pick
        setTimeout(() => {
          router.push({
            name: 'ProductDescription',
            query: {
              catId: listData.catId,
              price: listData.price,
              seller: '',
              tokenid: 'xx',
              identify: 'project',
              nftresidue: listData.nftresidue,
            },
          })
        })
      }

      // 上拉刷新
      let onLoad = () => {
        initData()
      }
      //替换图片
      let errorImg = require('@/assets/error.jpg')
      let imgerror = (e) => {
        e.target.src = errorImg
      }
      //通过hash值获取商品描述(为以后准备)
      // const ajax = new XMLHttpRequest()
      // ajax.open(
      //   'GET',
      //   'https://ipfs.infura.io/ipfs/QmSmtKCWr4DXQh9o4fJafdyGfs1rWEoH9Q5XasEQTZLRen'
      // )
      // ajax.send()
      // ajax.onreadystatechange = function (res) {
      //   if (ajax.readyState == 4) {
      //     console.log(ajax.responseText, '商品描述')
      //   }
      // }
      return {
        back,
        show,
        closeDialog,
        popupComponent,
        pickCommodity,
        pickClass,
        loading,
        isDetail,
        slip,
        onLoad,
        detailsList,
        ...toRefs(listData),
        imgerror,
        errorImg,
        resetPage,
        checkPrice,
        t,
        ipfs,
      }
    },
    components: {
      [Dialog.Component.name]: Dialog.Component,
      DetailsPopup,
      [List.name]: List,
    },
  }
</script>

<style lang="scss" scoped>
  .details {
    width: 100%;
    color: #fff;
    padding: 0.4103rem 0.3846rem 1.2821rem;
    .detailsList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        position: relative;
        width: 49%;
        height: 6.1538rem;
        margin-bottom: 0.4103rem;
        padding: 0.2051rem;
        background: linear-gradient(to bottom, #202945, #161e34);
        border-radius: 0.3077rem;
        border: 2px solid transparent;
        .detailsImg {
          width: 100%;
          height: 4.1026rem;
          img {
            width: 100%;
            border-radius: 0.2051rem;
            height: 100%;
          }
        }
        .title {
          color: #fff;
          font-size: 0.4103rem;
          margin: 0.2051rem 0;
        }
        .detailsNum {
          .num {
            font-size: 0.359rem;
            color: #e1ab01;
          }
        }
        .price {
          position: absolute;
          top: 0.4103rem;
          right: 0.4615rem;
          border-radius: 0.3077rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 0.6154rem;
          padding: 0 0.3846rem;
          background-color: #d12521;
          font-size: 0.359rem;
        }
      }
      .pick {
        border: 2px solid #d12521;
      }
    }
  }
</style>
